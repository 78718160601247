import {
    REMOVE_SIMPLE_VALUATION,
    SET_GLOBAL_LOADING,
    SET_PART_EXCHANGE_DATA,
    SET_WIDGET_CONFIG,
    SET_WIDGET_TEMPLATE,
    PX_THROW_ERROR,
    UPDATE_WIDGET_DISPLAY,
    SET_CONFIG,
    OPEN_ESTIMATE_MODAL,
    SET_MODAL_BUTTON_ENABLED,
    OPEN_ENGAGEMENT_MODAL,
    OPEN_PHOTO_MODAL,
    PX_ERROR,
} from './actionTypes';
import { IDispatchValue } from '../interfaces/IStore';
import { BS_MODAL_CLOSE_EVENT } from '../constants/config';
import { processValuation } from '../utility/general';
import { deletePartExchange, getWidgetParams } from '../service/ApiService';
import { isValuationFinished, isValuationFailed } from '../service/DataService';
import { IInitializeWidgetConfig, IWidgetState, IPartExchangeData } from '../interfaces/PxWidget';
import debounce from 'lodash/debounce';
import { batch } from 'react-redux';
import { useUpdateApiPX } from 'src/partExchange/hooks';

/**
 * Method sets boolean on global loading state
 * @param dispatch
 * @param state
 * @param initialized
 */
export function setLoading(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    state: boolean,
    initialized?: boolean
): void {
    dispatch({ type: SET_GLOBAL_LOADING, data: { state, initialized } });
}

/**
 * Method that removes simple valuation
 *
 * @param dispatch
 * @param widgetState
 */
export function removeSimpleValuation(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    widgetState: Partial<IWidgetState>
): void {
    setLoading(dispatch, true);
    deletePartExchange(dispatch, widgetState).then(() => {
        getWidgetParams(dispatch, widgetState).then(() => {
            dispatch({ type: REMOVE_SIMPLE_VALUATION });
            setLoading(dispatch, false);
        });
    });
}

/**
 * Method dispatches a global error with a custom message
 * @param dispatch
 * @param errorType
 */
export function throwGlobalError(dispatch: (dispatchValue: IDispatchValue<string>) => void, error: Error): void {
    dispatch({ type: PX_THROW_ERROR, data: error.message });
}

export function setPxError(dispatch: (dispatchValue: IDispatchValue<string>) => void, errorCode: string): void {
    dispatch({ type: PX_ERROR, data: errorCode });
}

/**
 * Method dispatches to store widgetState and adds to config and template
 * @param dispatch
 * @param widgetOptions
 */
export function setWidgetTemplate(dispatch: (dispatchValue: IDispatchValue<any>) => void, widgetOptions: any): void {
    batch(() => {
        dispatch({ type: SET_WIDGET_CONFIG, data: widgetOptions });
        dispatch({ type: SET_WIDGET_TEMPLATE, data: widgetOptions.useCase });
    });
}

export function setWidgetPartExchange(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    widgetPartExchangeData: Partial<IPartExchangeData>
): void {
    dispatch({ type: SET_PART_EXCHANGE_DATA, data: widgetPartExchangeData });
}

/**
 * Method add to store processed valuation from autobiz widget
 * @param dispatch
 */
export function updateWidgetValuation(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    widgetState: Partial<IWidgetState>,
    pxId?: string,
    mopId?: string,
    clearSession?: boolean
): void {
    const windowObj: any = window;

    if (windowObj.$) {
        windowObj.$(document).ready(() => {
            function listenValuation(): void {
                // must use flag to unregister listener because JQuery off doesn't work correctly
                if (listenValuation.down) {
                    return;
                }
                if (isValuationFinished()) {
                    if (!isValuationFailed()) {
                        dispatch({ type: UPDATE_WIDGET_DISPLAY, data: processValuation(windowObj.valuation) });
                        useUpdateApiPX(dispatch, widgetState, pxId, mopId, clearSession);
                    } else if (widgetState.partExchangeData && widgetState.partExchangeData.id) {
                        // if failed and last px extists
                        removeSimpleValuation(dispatch, widgetState);
                    }
                }
            }

            listenValuation.down = false;
            // save debounced function as prop so we can still have the down flag and last function used for 'off'
            listenValuation.debounced = debounce(listenValuation, 1000);

            // remove last listener
            if (windowObj.lastValuationListener) {
                // set down flag on function and try to remove it using JQuery off
                windowObj.lastValuationListener.down = true;
                windowObj.$('body').off(BS_MODAL_CLOSE_EVENT, '#myModal', windowObj.lastValuationListener.debounced);
                windowObj.$('body').off('click', '#add_to_cart_estimate', windowObj.lastValuationListener.debounced);
                windowObj.$('body').off('click', '#add_to_cart', windowObj.lastValuationListener.debounced);
                windowObj.$('body').off('click', '#button-pj', windowObj.lastValuationListener.debounced);
            }

            // save last listener func on window, because only one should be active at one time
            windowObj.lastValuationListener = listenValuation;

            // attach to both Bootstrap modal close event and submit button click
            windowObj.$('body').on(BS_MODAL_CLOSE_EVENT, '#myModal', listenValuation.debounced);
            windowObj.$('body').on('click', '#add_to_cart_estimate', listenValuation.debounced);
            windowObj.$('body').on('click', '#add_to_cart', listenValuation.debounced);
            windowObj.$('body').on('click', '#button-pj', listenValuation.debounced);
        });
    }
}

/**
 * Method opens autobiz modal
 * @param dispatch
 * @param widgetState
 */
export function openAutobizEstimateModal(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    widgetState: Partial<IWidgetState>,
    pxId?: string,
    mopId?: string,
    clearSession?: boolean
): void {
    updateWidgetValuation(dispatch, widgetState, pxId, mopId, clearSession);
    dispatch({ type: OPEN_ESTIMATE_MODAL });
}

export function openAutobizEngagementModal(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    widgetState: Partial<IWidgetState>
): void {
    updateWidgetValuation(dispatch, widgetState);
    dispatch({ type: OPEN_ENGAGEMENT_MODAL });
}

export function openAutobizPhotoModal(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    widgetState: Partial<IWidgetState>,
    pxId?: string,
    mopId?: string,
    clearSession?: boolean
): void {
    updateWidgetValuation(dispatch, widgetState, pxId, mopId, clearSession);
    dispatch({ type: OPEN_PHOTO_MODAL });
}

export function setConfig(
    dispatch: (dispatchValue: IDispatchValue<any>) => void,
    config: IInitializeWidgetConfig
): void {
    dispatch({ type: SET_CONFIG, data: config });
}

export function setModalButtonEnabled(dispatch: (dispatchValue: IDispatchValue<any>) => void, state: boolean): void {
    dispatch({ type: SET_MODAL_BUTTON_ENABLED, data: state });
}
