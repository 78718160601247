export enum DependencyType {
    Script = 'script',
    Style = 'link',
}
export interface IAssetDependency {
    url: string;
    type: DependencyType;
}

export const STYLESHEET: string = 'stylesheet';
export const BOOTSTRAP_URL: string = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js';
export const JQUERY_URL: string = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
export const AUTOBIZ_WIDGET_URL: string = '/assets/exchange/js/autobizExchange.js';

export const JQueryDependency = {
    type: DependencyType.Script,
    url: JQUERY_URL,
};

export const externalAssets: IAssetDependency[] = [
    JQueryDependency,
    {
        type: DependencyType.Script,
        url: BOOTSTRAP_URL,
    },
];
