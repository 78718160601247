import React, { FunctionComponent, memo } from 'react';
import { useRouter } from 'next/router';
import { ICarAccessory, ICarComment, IConfigurableCar } from '../../../interfaces/Car';
import { IDealer } from '../../../interfaces/Dealer';
import Characteristics from '../../v2/Characteristics';
import { isCarElectric } from '../../CheckoutMySummary/helpers/helpers';
import { ICarOptionsList } from '../../CheckoutMySummary/interfaces';
import CheckoutCarDetailsConfigurationOptions from './CheckoutCarDetailsConfigurationOptions';
import CheckoutCarDetailsFinanceQuoteOptions from './CheckoutCarDetailsFinanceQuoteOptions';
import CheckoutCarDetailsPrice from './CheckoutCarDetailsPrice';
import useTranslations from '@hooks/useTranslations';
import CheckoutDealerInfo from '../../CheckoutDealerInfo';
import { DealerInfo } from '../../MySummary/DealerInfo';
import { isBrandAC, isBrandAP, OFFER_COMMENT_TYPE } from '../../../constants/main';
import DataRenderer from './DataRenderer';
import { useSelector } from 'react-redux';
import FilterDuck from '../../../redux/filters/filter.duck';
import { FINANCE } from '../../../services/filters/filters';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../context/featureSwitchApp';
import DealerPartExchangeSummary from '../../PartExchangeSummary';
import { IDealerFee } from '../../DealerFees/DealerFeesTemplate';
import { IMyOrder, MySavedDealJourneyType } from '../../../redux/user/user.duck.interface';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { Redux } from 'src/redux/redux.interface';
import { getDealerInformation } from '@utils/getStockDealerInformations';
import routes from '../../../constants/routes';
import { useIsShowPriceExcludedVAT } from '@hooks/useIsShowPriceExcludedVAT';
import Link from '@components/Link';
import { useJourneyType } from '@hooks/useJourneyType';
import FinanceWidgetDuck from '../../../redux/financeWidget/financeWidget.duck';
import { IReduxFinanceQuote } from '../../../redux/financeWidget/financeWidget.duck.interface';

interface ICarDetailsProps {
    brand: string;
    exteriorColour: string;
    exteriorColourPrice: number;
    engine: any;
    gearbox: string;
    fuel: string;
    mainTitle: string;
    interiorColour: string;
    interiorColourPrice: number;
    finalPriceWithAccessories: number;
    finalPriceWithoutVat: number;
    finalPriceWithAccessoriesAndDiscounts: number;
    basePrice: number;
    toggleExtendedView: () => void;
    technicalFeaturesVisible: boolean;
    options: ICarOptionsList[];
    financeQuote: any;
    existingLanguage: string;
    className: string;
    dealer: IDealer;
    isParent: boolean;
    carDetail: IConfigurableCar;
    dealerId?: string;
    accessories?: ICarAccessory[];
    dealerDiscount?: number;
    fees?: IDealerFee[];
    comments?: ICarComment[];
    pxDiscountPrice: number;
    dealerPx?: string;
    financeQuoteId?: string;
    order?: IMyOrder;
    displayDealerInfo?: boolean;
}

const renderDealer = (dealer?: IDealer, dealerId?: string, displayDealerInfo?: boolean) => {
    if (!displayDealerInfo && dealer?.city) {
        return <CheckoutDealerInfo dealer={dealer} />;
    }
    if (dealerId) {
        return <DealerInfo id={dealerId} />;
    }
    return null;
};

const CheckoutCarDetails: FunctionComponent<ICarDetailsProps> = memo(
    ({
        exteriorColour,
        exteriorColourPrice,
        engine,
        fuel,
        gearbox,
        interiorColour,
        interiorColourPrice,
        finalPriceWithAccessories,
        finalPriceWithoutVat,
        finalPriceWithAccessoriesAndDiscounts,
        basePrice,
        toggleExtendedView,
        technicalFeaturesVisible,
        options,
        className,
        dealer,
        carDetail,
        dealerId,
        dealerPx,
        accessories,
        dealerDiscount,
        isParent = true,
        fees,
        comments,
        pxDiscountPrice,
        financeQuoteId,
        order,
        displayDealerInfo,
    }: ICarDetailsProps) => {
        const { t } = useTranslations();

        const router = useRouter();

        const { isRent } = useJourneyType();

        const financeQuote = useSelector((state: Redux) => FinanceWidgetDuck.getOwnState(state).financeQuoteList)?.find(
            (financeQuote: IReduxFinanceQuote) => financeQuote.id === financeQuoteId
        )?.financeQuote;

        const globalPaymentJourney = useSelector((state: Redux) => FilterDuck.getPaymentJourneyType(state));
        const paymentJourney = order?.sibling?.journeyType
            ? order?.sibling?.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH
                ? 'cash'
                : 'finance'
            : globalPaymentJourney;

        const isDealerLayerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED);
        const isDealerFeesEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED);

        const { currentDeal } = useCarDetailsDuck();

        const isShowPriceExcludedVAT: boolean = useIsShowPriceExcludedVAT();

        const isFinanceJourney = paymentJourney === FINANCE;

        const isOfferAndDealerLayerEnabled = !isParent && isDealerLayerEnabled;

        const dealerData = carDetail?.dealers?.length ? getDealerInformation(carDetail) : dealer;

        return (
            <div className={className}>
                <div className={`detailsBlock ${isOfferAndDealerLayerEnabled ? 'offerDetailBlock' : ''}`}>
                    <div className="detailsContent">
                        <p className="paragraph">
                            <span>{t('checkout.carConfig.label.engine')}</span>
                            <span data-testid="TESTING_CAR_ENGINE">{engine}</span>
                        </p>
                        <p className="paragraph">
                            <span>{t('checkout.carConfig.label.gearbox')}</span>
                            <span data-testid="TESTING_CAR_GEARBOX"> {gearbox}</span>
                        </p>
                        <p className="paragraph">
                            <span>{t('checkout.carConfig.label.energy')}</span>
                            <span data-testid="TESTING_CAR_FUEL">{fuel}</span>
                        </p>
                    </div>

                    <div className="priceSummary">
                        <div className="separatorLine" />

                        {!isRent && (
                            <CheckoutCarDetailsPrice
                                className={className}
                                carDetail={carDetail}
                                dataTestId="TESTING_TOTAL_PRICE"
                                price={basePrice}
                                priceTypeTranslation={
                                    isOfferAndDealerLayerEnabled
                                        ? t('offerDetail.originalPrice')
                                        : t('checkout.carConfig.original_price')
                                }
                                journeyType={paymentJourney}
                            />
                        )}

                        {isOfferAndDealerLayerEnabled && <div className="separatorLine" />}

                        {financeQuote && !isRent ? (
                            <CheckoutCarDetailsFinanceQuoteOptions financeQuote={financeQuote} className={className} />
                        ) : (
                            <>
                                <CheckoutCarDetailsConfigurationOptions
                                    carDetail={carDetail}
                                    exteriorColor={exteriorColour}
                                    exteriorColorPrice={exteriorColourPrice}
                                    interiorColour={interiorColour}
                                    interiorColourPrice={interiorColourPrice}
                                    options={options}
                                    order={order}
                                />

                                {dealerDiscount && isOfferAndDealerLayerEnabled ? (
                                    <DataRenderer
                                        className={className}
                                        title={t('offerDetail.dealerDiscount.title')}
                                        data={[
                                            {
                                                title: t('offerDetail.dealerDiscount'),
                                                priceInclTax: dealerDiscount > 0 ? -dealerDiscount : dealerDiscount,
                                            },
                                        ]}
                                    />
                                ) : null}
                            </>
                        )}
                        {!isDealerFeesEnabled && (
                            <>
                                <div className="separatorLine" />
                                <div className="finalPrice" data-testid="TESTING_FINAL_PRICE">
                                    <CheckoutCarDetailsPrice
                                        carDetail={carDetail}
                                        className={`${className} ${
                                            isOfferAndDealerLayerEnabled ? 'customPriceStyle' : ''
                                        }`}
                                        dataTestId="TESTING_TOTAL_CASH_PRICE"
                                        price={finalPriceWithAccessories}
                                        priceTypeTranslation={
                                            isOfferAndDealerLayerEnabled
                                                ? t('offerDetail.finalPrice')
                                                : t('checkout.carConfig.final_price')
                                        }
                                        fee={currentDeal?.extraFields?.reservationFee}
                                        journeyType={paymentJourney}
                                    />
                                </div>
                                {isShowPriceExcludedVAT && (
                                    <div className="finalPriceWithoutTax">
                                        <CheckoutCarDetailsPrice
                                            carDetail={carDetail}
                                            className={`${className} ${
                                                isOfferAndDealerLayerEnabled ? 'customPriceStyle' : ''
                                            }`}
                                            isPriceWithoutTax
                                            price={finalPriceWithoutVat}
                                            priceTypeTranslation=""
                                            journeyType={paymentJourney}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {(isDealerFeesEnabled || isOfferAndDealerLayerEnabled) && (
                            <>
                                {!financeQuoteId && dealerPx && (
                                    <>
                                        <div className="separatorLine" />
                                        <DealerPartExchangeSummary pxData={JSON.parse(dealerPx)} isCashJourney />
                                        <div className="separatorLine" />
                                    </>
                                )}
                                {comments && (
                                    <>
                                        <DataRenderer
                                            className={className}
                                            title={t('offerDetail.fees.comments.title')}
                                            data={JSON.parse(String(comments))
                                                ?.filter(
                                                    (comment: { type: string; text: string }) =>
                                                        comment.type === OFFER_COMMENT_TYPE
                                                )
                                                ?.map((comment: ICarComment) => ({ title: comment.text }))}
                                        />
                                        <div className="separatorLine" />
                                    </>
                                )}
                                {isRent && (
                                    <CheckoutCarDetailsPrice
                                        className={`${className} customPriceStyle bonusPriceStyle`}
                                        dataTestId="TESTING_TOTAL_LEASYS_PRICE"
                                        carDetail={carDetail}
                                        price={financeQuote?.monthlyPayment}
                                        priceTypeTranslation={t('offerDetail.totalPrice.title')}
                                        fee={currentDeal?.extraFields?.reservationFee}
                                        journeyType={paymentJourney}
                                        showScrappageSummary
                                        showEcobonus
                                    />
                                )}
                                {!isRent && (
                                    <CheckoutCarDetailsPrice
                                        className={`${className} customPriceStyle bonusPriceStyle`}
                                        dataTestId="TESTING_TOTAL_CASH_PRICE"
                                        carDetail={carDetail}
                                        price={
                                            !financeQuoteId
                                                ? finalPriceWithAccessoriesAndDiscounts -
                                                  pxDiscountPrice +
                                                  (carDetail?.importInputs?.accessoriesPrice || 0)
                                                : finalPriceWithAccessoriesAndDiscounts +
                                                  (carDetail?.importInputs?.accessoriesPrice || 0)
                                        }
                                        priceTypeTranslation={t('offerDetail.totalPrice.title')}
                                        fee={currentDeal?.extraFields?.reservationFee}
                                        journeyType={paymentJourney}
                                        subTitle={t('offerDetail.totalPrice.subTitle')}
                                        showScrappageSummary
                                        showEcobonus
                                    />
                                )}
                                {isShowPriceExcludedVAT && (
                                    <div className="finalPriceWithoutTax">
                                        <CheckoutCarDetailsPrice
                                            carDetail={carDetail}
                                            className={`${className} ${
                                                isOfferAndDealerLayerEnabled ? 'customPriceStyle' : ''
                                            }`}
                                            isPriceWithoutTax
                                            price={finalPriceWithoutVat}
                                            priceTypeTranslation=""
                                            journeyType={paymentJourney}
                                        />
                                    </div>
                                )}
                                {isFinanceJourney && isOfferAndDealerLayerEnabled && (
                                    <div className="infoText1_1">
                                        <span>{t('offerDetail.infoText1_1')}</span>
                                        <span>
                                            {t('offerDetail.infoText1_2')}{' '}
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.service-public.fr/particuliers/vosdroits/R49929"
                                            >
                                                https://www.service-public.fr/particuliers/vosdroits/R49929
                                            </a>
                                        </span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="viewMore" onClick={toggleExtendedView}>
                        <Link
                            primary
                            withArrowIcon
                            label={
                                technicalFeaturesVisible
                                    ? t('technicalFeatures.hideDetails')
                                    : t('checkout.carConfig.view_details')
                            }
                            marginBottom={20}
                        />
                        {isBrandAP && <div className="separatorLine" />}
                        <div className="extraInfoWrapper">
                            {financeQuote && isCarElectric(fuel) && (
                                <span className="ecologicalBonus">{t('checkout.ecologicalBonus.extraInfo')}</span>
                            )}
                        </div>
                    </div>
                    {isBrandAC && <div className="separatorLine" />}
                    {technicalFeaturesVisible && <Characteristics carDetails={carDetail} />}
                    {/* Show only in My Account */}
                    {[routes.MY_SAVES, routes.MY_ORDERS].includes(router.pathname) &&
                        renderDealer(dealerData, dealerId, displayDealerInfo)}
                    {!isFinanceJourney && isOfferAndDealerLayerEnabled && (
                        <div className="additionText">
                            <div className="separatorLine" />
                            <div className="infoText1_1">
                                <span>{t('offerDetail.additionText_1')}</span>
                                <span>{t('offerDetail.additionText_2')}</span>
                                <span>{t('offerDetail.additionText_3')}</span>
                                <span>
                                    {t('offerDetail.infoText1_2')}{' '}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.service-public.fr/particuliers/vosdroits/R49929"
                                    >
                                        https://www.service-public.fr/particuliers/vosdroits/R49929
                                    </a>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);
CheckoutCarDetails.displayName = 'CheckoutCarDetails';
export default CheckoutCarDetails;
