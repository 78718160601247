import React, { FC, useContext, useState, useCallback } from 'react';
import useTranslations from '@hooks/useTranslations';
import Button from '../Button';
import Cookies from 'js-cookie';
import {
    FEATURES_LIST,
    FeatureSwitchContext,
    IFeatureSwitchContext,
    IFinanceWidgetButtonType, IFinanceWidgetModalType,
} from '../../context/featureSwitchApp';
import { FeatureSwitchType, IFeatureSwitchValue, ISelectFSOption } from '@utils/featureSwitches.utils';
import FeatureSwitcher from '../FeatureSwitcher/index';
import { FEATURE_SWITCHES_COOKIE_NAME, IS_DEV } from '../../constants/main';
import { OptionsType } from 'react-select';
import { PaymentJourneyTypes } from '../../partExchange/interfaces/Default';
import { CarJourneyType } from '../../services/carList/carList.types';
import * as queryString from 'querystring';
import { BANK_CHECKOUT_JOURNEY_TYPES, CONTACT_OPTIONS } from '../../constants/enums';

interface IBaseProps {
    onClose: () => void;
}

const makeEncodedValue = (value: unknown) => window.btoa(JSON.stringify(value));

const getFeaturesFromCookie = () => {
    const value = Cookies.get(FEATURE_SWITCHES_COOKIE_NAME);

    return value ? JSON.parse(window.atob(value)) : {};
};

export const ModalFeatureSwitches: FC<IBaseProps> = ({ onClose }) => {
    const { t } = useTranslations();
    const featureSwitches = useContext(FeatureSwitchContext);

    const [featureStates, setFeatureStates] = useState<IFeatureSwitchContext>(getFeaturesFromCookie());
    const [isSelectAllowedCarJourneysValid, setIsSelectAllowedCarJourneysValid] = useState<boolean>(true);

    const createSelectOptions = (enumeration: object): OptionsType<ISelectFSOption> => {
        return Object.entries(enumeration).map(([key, value]) => ({
            value: value,
            label: key,
        }));
    };

    const handleOnClose = async (): Promise<void> => {
        if (!isSelectAllowedCarJourneysValid) return;

        const shouldRedirectToHomepage =
            featureStates.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY !==
            featureSwitches.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY;

        const currentQueryObject = queryString.decode((location.search ?? '').replace('?', ''));
        const newQueryString = queryString.encode({
            ...currentQueryObject,
            date: encodeURIComponent(new Date().toISOString()),
        });

        const newURL = `${location.protocol}//${location.host}${
            shouldRedirectToHomepage ? '' : location.pathname
        }?${newQueryString}`;

        Cookies.set(
            FEATURE_SWITCHES_COOKIE_NAME,
            makeEncodedValue(featureStates),
            process.env.NODE_ENV === 'development'
                ? {}
                : {
                      sameSite: 'strict',
                      secure: !IS_DEV,
                  }
        );

        location.replace(newURL);

        onClose();
    };

    const handleOnSwitcherChange: <ValueType>(fsObj: IFeatureSwitchValue<ValueType>) => void = useCallback(
        ({ name, value }) => {
            setFeatureStates((currentFeatureStates) => ({
                ...currentFeatureStates,
                [name]: value,
            }));
        },
        [setFeatureStates]
    );

    const renderFSwitcher = useCallback(
        (featureName: string, btnLabel: string, props: any) => (
            <FeatureSwitcher
                key={featureName}
                btnLabel={btnLabel}
                switcherName={featureName}
                switcherValue={featureStates[featureName] ?? featureSwitches[featureName]}
                onValueChange={handleOnSwitcherChange}
                {...props}
            />
        ),
        [featureStates, handleOnSwitcherChange, featureSwitches]
    );

    const all = Object.keys(FEATURES_LIST);

    const btnLabelsCore: { [key: string]: string } = {
        [FEATURES_LIST.FEATURE_SWITCH_IS_FINANCE_DISABLED]: 'isFinanceDisabled',
        [FEATURES_LIST.FEATURE_SWITCH_IS_CASH_DISABLED]: 'isCashDisabled',
        [FEATURES_LIST.FEATURE_SWITCH_BUDGET_CHANGE_ALLOWED]: 'isBudgetChangeAllowed',
        [FEATURES_LIST.FEATURE_SWITCH_CUSTOM_DEPOSIT_TEXT_ALLOWED]: 'isCustomDepositTextAllowed',
        [FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED]: 'isSOLEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED]: 'dealerLayerEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_BENEFITS_MODE_ENABLED]: 'benefitsModeEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_DISABLE_SAVE_PX_TO_MOP]: 'disableSavePxToMop',
        [FEATURES_LIST.FEATURE_SWITCH_CHECKOUT_PAYMENT_MOCK_ENABLED]: 'checkoutPaymentMockEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS]: 'allowedCarJourneys',
        [FEATURES_LIST.FEATURE_SWITCH_PX_ENABLED]: 'pxEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_PX_FINALIZATION_ENABLED]: 'pxFinalizationEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_PX_IGNORE_PROMO_CASH]: 'pxIgnorePromoCash',
        [FEATURES_LIST.FEATURE_SWITCH_PX_IGNORE_PROMO_FINANCE]: 'pxIgnorePromoFinance',
        [FEATURES_LIST.FEATURE_SWITCH_LANGUAGE_CONTROLS]: 'languageControls',
        [FEATURES_LIST.FEATURE_SWITCH_DYNAMIC_YIELD_ENABLED]: 'dynamicYieldEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_SPLUNK_ENABLED]: 'splunkEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_PROFORMA_CONSENT]: 'proformaConsent',
        [FEATURES_LIST.FEATURE_SWITCH_ENV_SWITCHER_ENABLED]: 'envSwitcherEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY]: 'defaultPaymentJourney',
        [FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY_STOCK]: 'defaultPaymentJourneyStock',
        [FEATURES_LIST.FEATURE_SWITCH_SHARE_CONFIGURATION_ENABLED]: 'shareConfiguration',
        [FEATURES_LIST.FEATURE_SWITCH_REINSURANCE_INFO_ENABLED]: 'reinsuranceInfo',
        [FEATURES_LIST.FEATURE_SWITCH_SAVE_BASKET_CTA_DISABLED]: 'saveBasketCTADisabled',
        [FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED]: 'dealerFeesEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_CARTE_GRISE_ENABLED]: 'carteGriseEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_MY_ACCOUNT_ORDERS_DISABLED]: 'myAccountOrdersDisabled',
        [FEATURES_LIST.FEATURE_SWITCH_SHOW_REMAINING_TO_PAY_INFO]: 'showRemainingToPayInfo',
        [FEATURES_LIST.FEATURE_SWITCH_PROMO_ENABLED]: 'promoLabelsEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_TIRES_LABELLING_ENABLED]: 'tiresLabellingEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_OFFER_VALIDITY_DATE_ENABLED]: 'showOfferValidityDate',
        [FEATURES_LIST.FEATURE_SWITCH_NIF_ENABLED]: 'NIF enabled',
        [FEATURES_LIST.FEATURE_SWITCH_SHOW_FINANCE_LEGAL]: 'showFinanceLegal',
        [FEATURES_LIST.FEATURE_SWITCH_APPLY_ADMINISTRATIVE_POWER]: 'applyAdministrativePower',
        [FEATURES_LIST.FEATURE_SWITCH_ENGINE_COMPARATOR]: 'engineComparatorEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_GIGYA_ENABLED]: 'gigyaEnabled',
        [FEATURES_LIST.FEATURE_SWITCH_AGENT_LOGIN_ENABLED]: 'agentLoginEnabled',
    };

    const additionalProps: { [key: string]: any } = {
        [FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS]: {
            switchType: FeatureSwitchType.MULTIPLE_VALUES,
            onValidate: (value: any) => setIsSelectAllowedCarJourneysValid(value),
            options: createSelectOptions(CarJourneyType),
        },
        [FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY]: {
            switchType: FeatureSwitchType.SELECT,
            options: createSelectOptions(PaymentJourneyTypes),
        },
        [FEATURES_LIST.FEATURE_SWITCH_BANK_CHECKOUT_JOURNEY_TYPES]: {
            switchType: FeatureSwitchType.MULTIPLE_VALUES,
            options: createSelectOptions(BANK_CHECKOUT_JOURNEY_TYPES),
        },
        [FEATURES_LIST.FEATURE_SWITCH_CONTACT_OPTIONS]: {
            switchType: FeatureSwitchType.MULTIPLE_VALUES,
            options: createSelectOptions(CONTACT_OPTIONS),
        },
        [FEATURES_LIST.FEATURE_SWITCH_BANK_CHECKOUT_DEFAULT_JOURNEY_TYPE]: {
            switchType: FeatureSwitchType.SELECT,
            options: createSelectOptions(BANK_CHECKOUT_JOURNEY_TYPES),
        },
        [FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY_STOCK]: {
            switchType: FeatureSwitchType.SELECT,
            options: createSelectOptions(PaymentJourneyTypes),
        },
        [FEATURES_LIST.FEATURE_SWITCH_LEGAL_CONSENT_VERSION]: {
            switchType: FeatureSwitchType.SELECT,
            options: [
                { value: 'v1', label: 'Old legal consent v1' },
                { value: 'v2', label: 'New legal consent v2' },
            ],
        },
        [FEATURES_LIST.FEATURE_SWITCH_CONFIGURABLE_TRIM_PAGE_VERSION]: {
            switchType: FeatureSwitchType.SELECT,
            options: [
                { value: 'v1', label: 'Old configurable trim page v1' },
                { value: 'v2', label: 'New configurable trim page v2' },
            ],
        },
        [FEATURES_LIST.FEATURE_SWITCH_FINANCE_WIDGET_VERSION]: {
            switchType: FeatureSwitchType.SELECT,
            options: [
                { value: 'v1', label: 'Old finance widget' },
                { value: 'v2', label: 'New finance widget' },
            ],
        },
        [FEATURES_LIST.FEATURE_SWITCH_FINANCE_WIDGET_TYPE]: {
            switchType: FeatureSwitchType.SELECT,
            options: createSelectOptions(IFinanceWidgetModalType),
        },
        [FEATURES_LIST.FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON]: {
            switchType: FeatureSwitchType.SELECT,
            options: createSelectOptions(IFinanceWidgetButtonType),
        },
        [FEATURES_LIST.FEATURE_SWITCH_MANDATORY_OPTIONS]: {
            switchType: FeatureSwitchType.TEXT,
        },
        [FEATURES_LIST.FEATURE_SWITCH_RENTING_PRODUCTS]: {
            switchType: FeatureSwitchType.TEXT,
        },
        [FEATURES_LIST.FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES]: {
            switchType: FeatureSwitchType.TEXT,
        },
        [FEATURES_LIST.FEATURE_SWITCH_CATALAN_ZIP_PREFIXES]: {
            switchType: FeatureSwitchType.TEXT,
        },
    };

    return (
        <>
            <div className="featureSwitches__wrapper">
                {all.map((featureName) => {
                    const btnLabel =
                        btnLabelsCore[featureName] ||
                        featureName.replace('FEATURE_SWITCH_', '').split('_').join(' ').toLowerCase();
                    const props: { [key: string]: any } = additionalProps[featureName] || {};
                    return renderFSwitcher(featureName, btnLabel, props);
                })}
            </div>
            <div className="featureSwitches__btns-wrapper">
                <Button className="featureSwitches__btns-wrapper__saveButton" primary onClick={handleOnClose}>
                    {t('featureSwitches.modal.button.save')}
                </Button>
            </div>
        </>
    );
};
