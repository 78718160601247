import React, { FC } from 'react';
import useTranslations from '@hooks/useTranslations';
import { Col, Grid, Row } from '../Grid';
import { CheckoutFinanceWidgetProps } from '.';
import { StyledRow } from './CheckoutFinanceWidgetStyled';
import { formatDisplayLine, labelsFormattingWithFG } from '@utils/FinanceWidget.utils';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';

const CheckoutFinanceWidgetTemplate: FC<CheckoutFinanceWidgetProps> = ({ financialSummaryData, className }) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();

    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);

    if (!financialSummaryData) return null;

    if (Object.keys(financialSummaryData).length !== 0) {
        const financialProductLabel = financialSummaryData.globalTexts?.financialProductLabel?.text ?? '';

        return (
            <div className={className}>
                <div>
                    <Grid className="finance-summary--grid">
                        <Row className="finance-summary--row-1" key={9999}>
                            <Col className="finance-summary--col-1" xs={12} md={12}>
                                <span>{financialProductLabel}</span>
                            </Col>
                        </Row>
                        {financialSummaryData.blocks.map((block) =>
                            block.displayLines.map((displayLine) => {
                                return (
                                    <StyledRow
                                        className={`finance-summary--row-2 ${
                                            displayLine.styleType && displayLine.styleType.bold ? 'bold-style' : ''
                                        } ${
                                            displayLine.styleType && displayLine.styleType.indent ? 'indent-style' : ''
                                        } ${isSOLEnabled ? 'SOL' : ''} ${
                                            displayLine.order > 5 ? 'finance-summary--row-margin' : ''
                                        }`}
                                        key={`${block.order}-${displayLine.order}`}
                                    >
                                        <Col
                                            className={`finance-summary--col-2 ${
                                                displayLine.styleType && displayLine.styleType.bold ? 'bold-style' : ''
                                            } ${isSOLEnabled ? 'SOL' : ''}`}
                                            style={{
                                                fontSize: labelsFormattingWithFG({
                                                    displaySize: displayLine?.styleType?.displaySize,
                                                }),
                                            }}
                                            xs={8}
                                            md={8}
                                        >
                                            <span>{displayLine.label}</span>
                                        </Col>
                                        <Col
                                            className={`finance-summary--col-3 ${
                                                displayLine.styleType && displayLine.styleType.bold ? 'bold-style' : ''
                                            } ${isSOLEnabled ? 'SOL' : ''}`}
                                            style={{
                                                fontSize: labelsFormattingWithFG({
                                                    displaySize: displayLine?.styleType?.displaySize,
                                                }),
                                            }}
                                            xs={4}
                                            sm={4}
                                            md={4}
                                        >
                                            <span
                                                data-testid={`TESTING_FINANCE_PRICE_${block.order}_${displayLine.order}`}
                                            >
                                                {formatDisplayLine(
                                                    displayLine.displayValue,
                                                    displayLine.value,
                                                    t,
                                                    formatMoney
                                                )}
                                            </span>
                                        </Col>
                                    </StyledRow>
                                );
                            })
                        )}
                        <div className="finance-summary__legalTextWrapper" data-testid="TESTING_LEGAL_TEXT">
                            {!isSOLEnabled && (
                                <div className="finance-summary__legalTitle">
                                    <span>{t('modal.financial.legalNotice')}</span>
                                </div>
                            )}
                            {financialSummaryData.legalTexts.map((legalText, key) => (
                                <div
                                    style={{
                                        fontSize: labelsFormattingWithFG({
                                            displaySize: legalText?.style?.displaySize,
                                        }),
                                    }}
                                    className="finance-summary__legalText2"
                                    key={key}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: legalText.text }} />
                                </div>
                            ))}
                        </div>
                    </Grid>
                </div>
            </div>
        );
    } else {
        console.error('FinancialSummaryConfiguration data:' + JSON.stringify(financialSummaryData));
        return (
            <div>
                <p>{t('checkout.financewidget.error')}</p>
            </div>
        );
    }
};

export default CheckoutFinanceWidgetTemplate;
