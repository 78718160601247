import { IPxValuation } from '../interfaces/PxWidget';

/**
 * Method returns px valuation after it's being processed in order to be displayed in px widget
 * @param valuationJSON
 * NOTE: tested
 */
export function processValuation(valuationJSON: any): IPxValuation {
    const {
        Valuation,
        CarDetails: { regNumber, makeName, modelName },
        bonusAmount,
        statusId,
    }: any = JSON.parse(valuationJSON);

    return {
        statusId,
        price: Valuation.estimateValuationApplied,
        licensePlate: regNumber,
        makeName,
        modelName,
        bonusAmount,
    };
}

/**
 * Method returns true if simple valuation has data on widgetData
 * @param widgetData
 * NOTE: tested
 */
export function noSimpleValuation(widgetData: any): boolean {
    if (widgetData) {
        return !widgetData.simpleValuation || (widgetData.simpleValuation && !widgetData.simpleValuation.price);
    }

    return false;
}

export function isEmptyObject(obj: object): boolean {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}
