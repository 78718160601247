import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import CheckoutMySummaryTemplate from './CheckoutMySummaryTemplate';
import { rem } from 'polished';

export const CheckoutMySummaryStyled = styled(CheckoutMySummaryTemplate)`
    .grid {
        & > .Collapsible {
            background: white;
            display: flex;
            flex-direction: column;
            border: 2px solid #ececee;
            margin-bottom: ${rem(20)};
            & > .Collapsible__trigger {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                padding: ${rem(16)} ${rem(44)} ${rem(16)} ${rem(16)};

                @media (min-width: ${breakpoints.sm}px) {
                    padding: ${rem(15)} ${rem(60)} ${rem(15)} ${rem(30)};
                }
                text-transform: uppercase;

                &:focus-visible {
                    outline: 1px dotted;
                }

                &.is-open {
                    border-bottom: 2px solid #ececee;
                    &:after {
                        transform: rotate(0deg);
                    }
                }

                &:after {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='14' viewBox='0 0 26 14' fill='none'%3E %3Cpath d='M1 13L13 1L25 13' stroke='%23CDCDCD' stroke-width='2' stroke-linejoin='bevel'/%3E %3C/svg%3E");
                    background-repeat: no-repeat;
                    position: absolute;
                    right: ${rem(30)};
                    height: ${rem(14)};
                    width: ${rem(26)};
                    display: block;
                    transform: rotate(180deg);
                    top: ${rem(20)};

                    @media (min-width: ${breakpoints.sm}px) {
                        top: initial;
                    }
                }
            }
            .Collapsible__contentInner {
                padding: ${rem(20)};

                .Collapsible__contentInner {
                    padding: 0;
                }
            }
            .secondaryText {
                font-size: ${rem(12)};
                text-transform: none;
            }
        }

        .offer {
            .Collapsible__trigger {
                position: relative;

                &.is-open {
                    border-bottom: 0;
                    &:after {
                        transform: rotate(180deg);
                    }
                }

                &:after {
                    right: ${rem(20)};
                    height: ${rem(11)};
                    width: ${rem(18)};
                    transform: rotate(0);
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.27965 10.0938L0.908203 1.6889L1.93567 0.657822L9.28007 8.03152L16.6279 0.657822L17.6554 1.6889L9.43567 9.93753L9.27965 10.0938Z' fill='%23796F6E'/%3E%3C/svg%3E");
                }
            }

            .Collapsible__contentInner {
                padding-top: 0;
            }

            .offerTitle {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                &__line {
                    width: ${rem(4)};
                    height: 100%;
                    background-color: ${({ theme }) => theme.colors.red5};
                    position: absolute;
                    left: -2px;
                    top: 0;
                }

                &__id {
                    text-transform: uppercase;
                    font-size: ${rem(18)};
                    line-height: ${rem(24)};
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: ${rem(250)};
                    font-weight: ${({ theme }) => theme.fontWeights.textMedium};
                }

                &__price {
                    text-transform: lowercase;
                    font-weight: ${({ theme }) => theme.fontWeights.textMedium};
                }

                &__expirationDate {
                    text-transform: initial;
                    font-size: ${rem(14)};
                    color: ${({ theme }) => theme.colors.grey13};
                }
            }
        }
    }
`;
