import { IConfigurableCar } from '../../../../interfaces/Car';

const CONSUMPTION = 'Consommation WLTP';
const co2CombinedDataKey = 'co2Combined';
const consumptionCombinedDataKey = 'consumptionCombined';
const electricRangeDataKey = '0010';
const electricRangeDataKey2 = 'electricRange';
const bootSpaceDataKey = '0014';
const bootSpaceDataValue = '0018';
const motorizationKey1 = '0001';
const motorizationKey2 = '0002';
const motorizationKey3 = '0003';

enum HelperLabels {
    power = 'power',
    consumptionCombined = 'consumptionCombined',
    co2Combined = 'co2Combined',
    electricRange = 'electricRange',
}

interface TechTargetData {
    label: string;
    value: string;
    key: string;
}

export const getBootSpaceValue = (carDetails: IConfigurableCar) => {
    return carDetails?.technicalSpecifications
        ?.find((specification) => specification.key === bootSpaceDataKey)
        ?.data?.find((boot: TechTargetData) => boot.key === bootSpaceDataValue)?.value;
};

export const getStockEnvironmentalData = (stockCarDetail: IConfigurableCar) => {
    if (!stockCarDetail) return;
    const { technicalSpecifications } = stockCarDetail;
    if (typeof technicalSpecifications === 'undefined') return;

    const consumptions = technicalSpecifications.find((specification) => specification.category === CONSUMPTION);
    const motorization = technicalSpecifications.filter(
        (specification) =>
            specification.key === motorizationKey1 ||
            specification.key === motorizationKey2 ||
            specification.key === motorizationKey3
    );
    let powerData: TechTargetData | undefined;
    motorization.filter((motor) => {
        const kwData = motor.data.filter((tech: TechTargetData) => tech.label.toLowerCase().includes('kw'));
        if (kwData.length) powerData = kwData[0];
    });
    if (powerData?.value) powerData.value = powerData?.value.split(' ')[0];

    const consumptionCombinedData: TechTargetData | undefined = consumptions?.data.find(
        (target: TechTargetData) => target.key === consumptionCombinedDataKey
    );

    const co2CombinedData: TechTargetData | undefined = consumptions?.data.find(
        (target: TechTargetData) => target.key === co2CombinedDataKey
    );

    const electricRangeData: TechTargetData | undefined = consumptions?.data.find(
        (target: TechTargetData) => target.key === electricRangeDataKey || target.key === electricRangeDataKey2
    );

    const result = {
        power: { value: powerData?.value, label: HelperLabels.power },
        consumptionCombined: { value: consumptionCombinedData?.value, label: HelperLabels.consumptionCombined },
        co2Combined: { value: co2CombinedData?.value, label: HelperLabels.co2Combined },
        electricRange: { value: electricRangeData?.value, label: HelperLabels.electricRange },
    };

    return result;
};
