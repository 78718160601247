import { useCallback } from 'react';
import UIDuck from '../redux/commonDucks/ui.duck';
import Cookies from 'js-cookie';
import {
    MOP_ID_COOKIE_NAME,
    MOP_CAR_CONFIGURATION_COOKIE_NAME,
    MOP_JOURNEY_COOKIE_NAME,
    SESSION_DOMAIN,
    IS_DEV,
} from '../constants/main';
import routes from '../constants/routes';
import sessionInfoApi from '../services/core/seassionInfoApi.service';
import { dealerApi } from '../services/dealer/dealer.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { IConfigurableCar, IDeal } from '../interfaces/Car';
import { Dispatch } from 'redux';
import { IPxValuation } from '../partExchange/interfaces/PxWidget';
import { useDispatch, useSelector } from 'react-redux';
import carDetailsDuck from '../redux/carDetails/carDetails.duck';
import FilterDuck from '../redux/filters/filter.duck';
import ConfiguratorDuck from '../redux/configurator/configurator.duck';
import { TBudgetType } from '../interfaces/IFilters';
import useCustomRouter from './useCustomRouter';
import { useFeatureSwitchEnabled } from './useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import { stepCodes } from './useUpdateStepCode';
import { useUserDuck } from './useUserDuck';
import { constructPsaCheckoutUrl } from '@utils/Deal.utils';
import { useExternalCheckout } from './useExternalCheckout';
import { gaCrossDomainParameter } from '@utils/gaCrossDomainParameter';

interface IUpdateDealProp {
    dealerId: string;
    token: string;
}

export const useCreateMopAndGoToCheckout = () => {
    const router = useCustomRouter();
    const dispatch: Dispatch = useDispatch();

    const isDealerSelectionCheckoutRedirectAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT
    );

    const { externalCheckoutEnabled, checkoutUrl } = useExternalCheckout();

    const {
        carDetails,
        pxState,
        paymentJourney,
        currentDeal,
    }: {
        carDetails: IConfigurableCar;
        pxState: IPxValuation;
        paymentJourney: TBudgetType;
        currentDeal?: IDeal;
    } = useSelector((state) => ({
        paymentJourney: FilterDuck.getPaymentJourneyType(state),
        carDetails: carDetailsDuck.getOwnState(state).currentCarDetails,
        pxState: ConfiguratorDuck.getOwnState(state).pxState,
        currentDeal: carDetailsDuck.getOwnState(state).currentDeal,
    }));

    const isDealerChoiceEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED);
    const isSavePxToMopDisabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DISABLE_SAVE_PX_TO_MOP);
    const { isUserLoggedIn } = useUserDuck();

    const createMopAndGoToCheckout: (updateDeal?: IUpdateDealProp) => void = useCallback(
        async (updateDeal) => {
            dispatch(UIDuck.setLoading(5));
            let mopId = currentDeal?.mopId;

            // if MOP not created or MOP created with different car configuration or MOP id on Deal not the same
            if (!mopId) {
                try {
                    const { data } = await sessionInfoApi.createRetailMop(getSessionIdCookie());
                    mopId = data.mopId;
                    // save MOP ID and the car configuration
                    Cookies.set(MOP_ID_COOKIE_NAME, mopId, {
                        domain: SESSION_DOMAIN,
                        sameSite: 'strict',
                        secure: !IS_DEV,
                    });
                    Cookies.set(MOP_CAR_CONFIGURATION_COOKIE_NAME, carDetails.externalId, {
                        domain: SESSION_DOMAIN,
                        sameSite: 'strict',
                        secure: !IS_DEV,
                    });
                    Cookies.set(MOP_JOURNEY_COOKIE_NAME, paymentJourney, {
                        domain: SESSION_DOMAIN,
                        sameSite: 'strict',
                        secure: !IS_DEV,
                    });
                } catch (err: any) {}
            }

            // we need to link saved PX to MOP
            if (!isSavePxToMopDisabled && pxState && pxState.id) {
                await sessionInfoApi.linkPxToMop(getSessionIdCookie(), mopId, pxState.id);
            }

            if (isDealerSelectionCheckoutRedirectAllowed && updateDeal?.dealerId) {
                await dealerApi.updateDeal(updateDeal.dealerId, updateDeal.token);
            }

            if (externalCheckoutEnabled) {
                window.location.assign(constructPsaCheckoutUrl(checkoutUrl, mopId, isUserLoggedIn));
            } else {
                try {
                    router.push(
                        carDetails.stock || !!currentDeal.fullProductConfiguration?.dealers?.length || isDealerChoiceEnabled
                            ? routes.CHECKOUT_MY_DETAILS
                            : gaCrossDomainParameter(routes.CHECKOUT_DELIVERY)
                    );
                } catch (err: any) {
                } finally {
                    dispatch(UIDuck.setLoading(-5));
                }
            }
        },
        [
            carDetails?.externalId,
            pxState?.id,
            paymentJourney,
            currentDeal?.mopId,
            isSavePxToMopDisabled,
            externalCheckoutEnabled,
            checkoutUrl,
            currentDeal?.fullProductConfiguration?.dealers?.length,
        ]
    );

    return createMopAndGoToCheckout;
};
