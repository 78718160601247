import { BRAND, LANGUAGE, MARKET } from '../../constants/main';
import { getSessionIdCookie } from '@utils/Session.utils';

export const API: string = 'api';
export const WIDGET_PARAMS: string = 'widget-params';
export const PX_CREATE: string = 'create';
export const PX_UPDATE: string = 'update';
export const PX_DELETE: string = 'delete';

export const TIMEOUT_PERIOD: number = 30000;
export const APPLICATION_JSON: string = 'application/json';
export const AUTH_TOKEN: string = getSessionIdCookie() || '';
export const getAuthTokenFromCookie: () => string = () => getSessionIdCookie() || '';

const ENDPOINT_BASE: string = `/${API}/${MARKET}/${LANGUAGE}/${BRAND}`;

export const WIDGET_PARAMS_ENDPOINT: string = `${ENDPOINT_BASE}/${WIDGET_PARAMS}`;
export const PX_CREATE_ENDPOINT: string = `${ENDPOINT_BASE}/${PX_CREATE}`;
export const PX_UPDATE_ENDPOINT: string = `${ENDPOINT_BASE}/${PX_UPDATE}`;
export const PX_DELETE_ENDPOINT: string = `${ENDPOINT_BASE}/${PX_DELETE}`;
