import React from 'react';
import parser from 'bbcode-to-react';

export const useRenderDescription = () => {
    function transformDescriptionToList(description: string): JSX.Element | string {
        const [standardText, ...listItems] = description.split('#').map((line) => line.trim());

        return listItems.length > 0 ? (
            <ul data-testId="renderDescriptionTestId">
                <li style={{ listStyle: 'none', marginLeft: '-5%' }} className="firstListItem">
                    {parser.toReact(standardText)}
                </li>
                {listItems.map((item, index) => (
                    <li style={{ marginTop: '5px' }} key={`renderDescription_${index}`}>
                        {parser?.toReact(item.replace(/-/, ''))}
                    </li>
                ))}
            </ul>
        ) : (
            description
        );
    }

    const renderDescription = (description: string): JSX.Element | string => {
        const formattedDesc = description.includes('#') ? transformDescriptionToList(description) : description;

        return typeof formattedDesc === 'string' ? parser?.toReact(formattedDesc) : formattedDesc;
    };

    return {
        renderDescription,
        transformDescriptionToList,
    };
};
