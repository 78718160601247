import { PLAUSIBLE_HEAD_SCRIPT_ENABLED } from 'src/constants/main';
import { useCallback } from 'react';

export enum UserEvent {
    HelpNeeded = 'help_needed',
    ContactRequested = 'contact_requested',
    SaveCarSelector = 'save_car_selector',
    SendCarConfiguration = 'send_car_configuration',
    ContinueCtaBasket = 'continue_cta_basket',
    SaveCarBasket = 'save_car_basket',
}

declare global {
    interface Window {
        plausible: (userEvent: UserEvent) => void;
    }
}

export const usePlausible = () => {
    const plausibleUserEvent = useCallback(
        (userEvent: UserEvent) => {
            if (PLAUSIBLE_HEAD_SCRIPT_ENABLED && window.plausible) {
                console.log('Plausible event', userEvent);
                window.plausible(userEvent);
            }

            return null;
        },
        [PLAUSIBLE_HEAD_SCRIPT_ENABLED]
    );

    return {
        plausibleUserEvent,
    };
};
