import { AxiosPromise, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import pxInstance from '../api/pxConfig';
import { PX_CREATE_ENDPOINT, PX_DELETE_ENDPOINT, PX_UPDATE_ENDPOINT, WIDGET_PARAMS_ENDPOINT } from '../constants/api';
import { PxErrorCodes, pxWidgetConfig } from '../constants/config';
import { AUTOBIZ_WIDGET_URL, DependencyType } from '../../utils/externalDependencies/dependencies';
import { PART_EXCHANGE_ERROR_NULL } from '../constants/errors';
import { IDispatchValue } from '../interfaces/IStore';
import { IPartExchangeData, IWidgetState } from '../interfaces/PxWidget';
import {
    setModalButtonEnabled,
    setPxError,
    setWidgetPartExchange,
    setWidgetTemplate,
    throwGlobalError,
} from '../store/actions';
import { captureException } from '@utils/sentry.utils';
import { processWidgetData } from './DataService';
import coreAPIService from '../../services/core/coreApi.service';
import AssetManager from '@utils/externalDependencies/AssetManager';

/**
 * Method calls widget params endpoint and applies widget data to state
 * @param dispatch
 * NOTE: tested
 */
export function getWidgetParams(
    dispatch: Dispatch<IDispatchValue<string | {} | null>>,
    widgetState: Partial<IWidgetState>,
    pxId?: string,
    mopId?: string,
    evaluateToken: boolean = false
): AxiosPromise {
    const {
        co2,
        energy,
        lcdv,
        ncPrice,
        mopId: mopIdFromConfig,
        ignorePromo,
        allowPxFinalization,
    } = widgetState.config!;
    const resolvedMopId = allowPxFinalization ? mopId || mopIdFromConfig : null;
    const widgetPromise: AxiosPromise = pxInstance.get(`${WIDGET_PARAMS_ENDPOINT}`, {
        params: {
            pxId,
            co2,
            energy,
            lcdv,
            ncPrice,
            mopId: resolvedMopId,
            ignorePromo,
            evaluateToken: evaluateToken === true || null,
            // no way, even partExchangeId is present, we might need to call evaluation, due to the change of car, or journey...
            // noUpdate: !!widgetState.config.partExchangeId,
        },
    });
    widgetPromise.then(({ status, data: { widgetOptions, partExchange } }: AxiosResponse) => {
        const processedWidgetOptions = {
            ...processWidgetData(widgetOptions),
            mopId: allowPxFinalization ? widgetOptions.mopId || resolvedMopId : null,
            ignorePromo: widgetOptions.ignorePromo || ignorePromo,
        };

        const widgetPartExchangeData: IPartExchangeData = partExchange;
        // wait for already loading assets(JQuery & bootstrap) and then load autobiz js
        AssetManager.instance
            .then(() => {
                // Load autobiz widget js after domain is available
                if (pxWidgetConfig.env === 'dev') {
                    AssetManager.instance.addScripts([
                        {
                            type: DependencyType.Script,
                            url: widgetOptions.domain + AUTOBIZ_WIDGET_URL,
                        },
                    ]);
                } else {
                    AssetManager.instance.addScriptsAndSuppressErrors([
                        {
                            type: DependencyType.Script,
                            url: widgetOptions.domain + AUTOBIZ_WIDGET_URL,
                        },
                    ]);
                }
                // enable button
                AssetManager.instance.then(() => setModalButtonEnabled(dispatch, true));
            })
            .catch(e => {
                captureException(e);
                throwGlobalError(dispatch, e);
            });
        setWidgetTemplate(dispatch, processedWidgetOptions);
        setWidgetPartExchange(dispatch, widgetPartExchangeData);

        if (status === 400) setPxError(dispatch, PxErrorCodes.WRONG_CREDENTIALS);
        if (status === 500) setPxError(dispatch, PxErrorCodes.UNAVAILABLE);
    });
    return widgetPromise;
}

export function updateCompletedPXInOrder(dealId: string): AxiosPromise {
    return coreAPIService.post(`digital-api/api/part-exchanges/${dealId}/confirm`, {}, {});
}

/**
 * Method calls create endpoint to create the part exchange with the given valuation key
 */
export function createPartExchange(widgetState: Partial<IWidgetState>, clearSession?: boolean): AxiosPromise {
    let parsedValuation: any = '';
    if ((window as any).valuation) {
        parsedValuation = JSON.parse((window as any).valuation);
    }
    const widgetPromise: AxiosPromise = pxInstance.post(
        `${PX_CREATE_ENDPOINT}/${parsedValuation.valuationKey}`,
        {},
        {
            params: { clearSession: !!clearSession },
        }
    );
    return widgetPromise;
}

export function updatePartExchange(widgetState: Partial<IWidgetState>): AxiosPromise {
    let parsedValuation: any = '';
    if ((window as any).valuation) {
        parsedValuation = JSON.parse((window as any).valuation);
    }

    // TODO Update PX_StatusID on BE APP-13149
    // widgetState.partExchangeData.statusId

    const widgetPromise: AxiosPromise = pxInstance.patch(
        `${PX_UPDATE_ENDPOINT}/${widgetState.partExchangeData!.id}/${parsedValuation.valuationKey}`,
        {},
        {
            params: {},
        }
    );
    return widgetPromise;
}

/**
 * Method calls delete endpoint to remove part exchange
 */
export function deletePartExchange(
    dispatch: Dispatch<IDispatchValue<any>>,
    widgetState: Partial<IWidgetState>
): AxiosPromise {
    if (widgetState.partExchangeData && !widgetState.partExchangeData.id) {
        throwGlobalError(dispatch, new Error(PART_EXCHANGE_ERROR_NULL));
    }

    const widgetPromise: AxiosPromise = pxInstance.delete(`${PX_DELETE_ENDPOINT}/${widgetState.partExchangeData!.id}`);

    return widgetPromise;
}
