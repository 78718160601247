import { BRAND, LANGUAGE } from '../../constants/main';
import { IObjectKey } from '../interfaces/Default';

export const PxStatus = {
    ENGAGEMENT: 110,
    PICTURE: 210,
    COMPLETED: 320,
};
export const PxStatusFromCode = (code: number) =>
    code === PxStatus.ENGAGEMENT
        ? 'engagement'
        : code === PxStatus.PICTURE
        ? 'picture'
        : code === PxStatus.COMPLETED
        ? 'completed'
        : 'estimation';

export const PxErrorCodes = {
    WRONG_CREDENTIALS: '400-1',
    MISSING_VALUATION: '400-2',
    UNAVAILABLE: '500',
};

export const pxWidgetConfig: IObjectKey = {
    brand: BRAND,
    language: LANGUAGE || '',
    country: LANGUAGE || '',
    env: process.env.APP_ENV || '',
};

export const DOMAIN: string = 'domaine';
export const BLOCK_CONTAINER: string = 'blocContainer';
export const TEMPLATE_ID: string = 'templateID';

export const CONFIG_TRANSLATIONS: any = {
    domain: DOMAIN,
    blockContainer: BLOCK_CONTAINER,
    templateId: TEMPLATE_ID,
};

export const BS_MODAL_CLOSE_EVENT: string = 'hidden.bs.modal';
export const AUTOBIZ_STATUS_SUCCESS: number[] = [110, 210, 320]; //110 estimate, 210 engagement
export const AUTOBIZ_LAST_ESTIMATION_STEP: string = 'estimate-your-valuation';
