import React, { useContext, useEffect, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { IAggregatedTrim, ISelectedSpeckPackAndEngine } from 'src/redux/carDetails/carDetails.duck.interface';
import Spinner from '@components/Spinner';
import { getMonthlyPriceObjByType, getTotalPriceObjByType } from '@utils/Price.utils';
import { CarJourneyType, IFeature, IPriceV2Types } from 'src/services';
import CarDetailsService from 'src/services/carDetails/carDetails.service';
import PriceSuffix from '@components/PriceSuffix';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from 'src/context/featureSwitchApp';
import { orderBy } from 'lodash';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import GlobalDuck from 'src/redux/global/global.duck';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { EMPLOYEE_PRICE_TYPE } from 'src/services/filters/filters';
import { EContextPage, findPromoByPageAndType } from '@utils/Promo.utils';
import MarketingSticker from '@components/MarketingSticker';
import { IPromo } from 'src/interfaces/IOfferItem';
import parser from 'bbcode-to-react';
import { MAXIMUM_POWER_KW, STANDARD_FEATURES_FS_PREFIX } from 'src/constants/characteristics';
import MonthlyPriceLabel, { AprSizes } from '@components/MonthlyPriceLabel';
import { SC } from 'src/styles/theme';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB, ModalVersion, ROLES } from 'src/constants/main';
import {
    getUniqueEquipments,
    getUniqueExteriorColors,
    getUniqueFeatures,
    getUniqueInteriors,
    isEquipmentAvailableInConfiguration,
    isExteriorColorAvailableInConfiguration,
    isFeatureAvailableInConfiguration,
    isInteriorAvailableInConfiguration,
} from '@utils/TrimCompare.utils';
import Collapsible from 'react-collapsible';
import { IGlobalState, Redux } from 'src/redux/redux.interface';
import { BackButton, CloseButton } from '@components/Modal/ModalComparators/shared/CloseButton';
import Select from '@components/Select';
import Button from '@components/Button';
import {
    AVG_TITLE_HEIGHT,
    calculateSummaryData,
    ELEMENT_PADDING,
    findTrimBySelectedSpeckPackAndEngine,
    GEARBOX_SEARCH_KEY,
    getMainImageUrl,
    hasTrimLabelOrDescriptionOrTitle,
    KEY_ALL,
    KEY_COLORS,
    KEY_ENGINES,
    KEY_FEATURES,
    KEY_INTERIORS,
    KEY_PROPERTIES,
    KEY_STICKY_AVAILABILITY,
    KEY_STICKY_BACK_BUTTON,
    KEY_STICKY_BUTTON,
    KEY_STICKY_NAME,
    KEY_STICKY_PRICE,
    KEY_STICKY_SELECT,
    KEY_STRONG_POINTS,
    KEY_TRIMS,
    parseSelectedFiltersToRequest,
    redirectToConfigurator,
    SCROLL_ELEMENT_CLASS_NAME,
    SELECTED,
    setUniqueAndSelectedPacksAndEnginesForComparison,
    TESTING_PREFIX,
    TRIM_DESKTOP_WIDTH_WITH_PADDING,
    useStickyListenerDesktop,
} from '@components/Modal/ModalComparators/ModalTrimCompare/shared';
import { IconTemplate } from '@components/Icon/IconTemplate';
import Icon, { Icons } from '@components/Icon';
import FilterDuck from '../../../../redux/filters/filter.duck';
import { getData } from '@components/CarOptionsInfoBox/helpers';
import TrimSelectorDuck from '../../../../redux/trimSelector/trimSelector.duck';
import IconButton from '@components/IconButton';
import { useRenderDescription } from '@hooks/useRenderDescription';
import { getFuelTypeByCode } from '@utils/Fuel.utils';

export interface ITrimCompareStyled extends SC {
    countOfTrims?: number;
    className?: string;
}

const MIN_WIDTH_FOR_3_VEHICLES = 1200;
const MIN_WIDTH_FOR_4_VEHICLES = 1500;

const ModalTrimCompareTemplateDesktop = ({ className }: ITrimCompareStyled) => {
    const { t } = useTranslations();
    const { formatMoney } = useMoneyFormatter();
    const router = useRouter();
    const dispatch = useDispatch();

    const { renderDescription } = useRenderDescription();

    const [activeFeature, setActiveFeature] = useState(KEY_ALL);

    const [allTrims, setAllTrims] = useState(null);
    const [uniquePacksAndEnginesForComparison, setUniquePacksAndEnginesForComparison] = useState(null);
    const [selectedPacksAndEnginesForComparison, setSelectedPacksAndEnginesForComparison] = useState(null);
    const [selectedTrims, setSelectedTrims] = useState(null);
    const [uniqueInteriorColors, setUniqueInteriorColors] = useState([]);
    const [uniqueFeatures, setUniqueFeatures] = useState([]);
    const [uniqueExteriorColors, setUniqueExteriorColors] = useState([]);
    const [isSomeSelectedVehicleAsElectric, setIsSomeSelectedVehicleAsElectric] = useState(false);
    const [isSomeSelectedVehicleAsPlugInHybrid, setIsSomeSelectedVehicleAsPlugInHybrid] = useState(false);
    const [isSomeSelectedVehicleAsDieselOrBensinOrHybrid, setIsSomeSelectedVehicleAsDieselOrBensinOrHybrid] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dipslaySelectedTrimValues, setDisplaySelectedTrimValues] = useState(false);

    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);

    const carJourney = useSelector<IGlobalState, CarJourneyType>((state) => GlobalDuck.getCarJourney(state));
    const filters = useSelector((state) => FilterDuck.getFilters(state));
    const filteredTrims = useSelector((state: Redux) => TrimSelectorDuck.getFilteredTrims(state));

    useEffect(() => {
        if (allTrims?.length > 0) {
            const element = document?.getElementsByClassName(SCROLL_ELEMENT_CLASS_NAME)?.[0];

            let maxCountOfVisibleTrimsForCurrentWidth = 2;
            if (element.clientWidth > MIN_WIDTH_FOR_3_VEHICLES && allTrims?.length >= 3) {
                maxCountOfVisibleTrimsForCurrentWidth = 3;
            }

            if (element.clientWidth > MIN_WIDTH_FOR_4_VEHICLES && allTrims?.length >= 4) {
                maxCountOfVisibleTrimsForCurrentWidth = 4;
            }

            setUniqueAndSelectedPacksAndEnginesForComparison(
                allTrims,
                setUniquePacksAndEnginesForComparison,
                setSelectedPacksAndEnginesForComparison,
                maxCountOfVisibleTrimsForCurrentWidth
            );
        }
    }, [allTrims]);

    useEffect(() => {
        const preselectedTrims = [] as IAggregatedTrim[];
        let foundedElectricVehicle = false;
        let foundedPlugInHybridVehicle = false;
        let foundedDieselOrBensinOrHybridVehicle = false;

        setIsSomeSelectedVehicleAsElectric(false);
        setIsSomeSelectedVehicleAsDieselOrBensinOrHybrid(false);

        selectedPacksAndEnginesForComparison?.map((selectedSpecPackAndEngine: ISelectedSpeckPackAndEngine) => {
            const trim = findTrimBySelectedSpeckPackAndEngine(allTrims, selectedSpecPackAndEngine, true);

            const { isBenzin, isDiesel, isHybrid, isPlugInHybrid } = getFuelTypeByCode(
                trim?.engines?.[0]?.cheapestCar?.fuelCode
            );
            const isBenzinOrDiesel = isDiesel || isBenzin;

            if (!foundedPlugInHybridVehicle && isPlugInHybrid) {
                setIsSomeSelectedVehicleAsPlugInHybrid(true);
                foundedPlugInHybridVehicle = true;
            }

            if (!foundedElectricVehicle && !isBenzinOrDiesel && !isHybrid && !isPlugInHybrid) {
                setIsSomeSelectedVehicleAsElectric(true);
                foundedElectricVehicle = true;
            }

            if (!foundedDieselOrBensinOrHybridVehicle && (isBenzinOrDiesel || isHybrid)) {
                setIsSomeSelectedVehicleAsDieselOrBensinOrHybrid(true);
                foundedDieselOrBensinOrHybridVehicle = true;
            }

            preselectedTrims.push(findTrimBySelectedSpeckPackAndEngine(allTrims, selectedSpecPackAndEngine, true));
        });

        setUniqueInteriorColors(getUniqueInteriors(preselectedTrims));
        setUniqueFeatures(getUniqueFeatures(preselectedTrims));
        setUniqueExteriorColors(getUniqueExteriorColors(preselectedTrims));
        setSelectedTrims(preselectedTrims);
    }, [selectedPacksAndEnginesForComparison]);

    useEffect(() => {
        setIsLoading(true);

        CarDetailsService.getCarsDetailsWithAllOptionsV2(
            router?.query?.model?.[0] as string,
            parseSelectedFiltersToRequest(filters)
        ).then((trims) => {
            const sortedTrimIds = filteredTrims?.map((trim) => trim.specPack.id);
            setAllTrims(
                trims?.trims?.sort(
                    (trimA: { id: string }, trimB: { id: string }) =>
                        sortedTrimIds.indexOf(trimA.id) - sortedTrimIds.indexOf(trimB.id)
                )
            );
            setIsLoading(false);
            setTimeout(() => setDisplaySelectedTrimValues(true), 500);
        });
    }, []);

    useEffect(() => {
        const element = document?.getElementsByClassName(SCROLL_ELEMENT_CLASS_NAME)?.[0];
        const trimsTitle = document?.getElementById(`TITLE_${KEY_TRIMS}`);
        const keyFeatures = document?.getElementById(`TITLE_${KEY_STRONG_POINTS}`);
        const colorsTitle = document?.getElementById(`TITLE_${KEY_COLORS}`);
        const interiorsTitle = document?.getElementById(`TITLE_${KEY_INTERIORS}`);
        const featuresTitle = document?.getElementById(`TITLE_${KEY_FEATURES}`);
        const backButton = document?.getElementById(KEY_STICKY_BACK_BUTTON);

        const trimsSectionTitleTxt = document?.getElementById(`TITLE_TXT_${KEY_TRIMS}`);

        element?.addEventListener('scroll', () => {
            trimsSectionTitleTxt.style.display = element.scrollTop > AVG_TITLE_HEIGHT ? 'none' : 'block';
            backButton.style.top = element.scrollTop > AVG_TITLE_HEIGHT ? '2px' : '15px';

            if (
                element.clientWidth + element?.scrollLeft <=
                (selectedPacksAndEnginesForComparison?.length || 1) * TRIM_DESKTOP_WIDTH_WITH_PADDING + ELEMENT_PADDING
            ) {
                trimsTitle.style.marginLeft = `${element?.scrollLeft}px`;
                colorsTitle.style.marginLeft = `${element?.scrollLeft}px`;
                interiorsTitle.style.marginLeft = `${element?.scrollLeft}px`;
                featuresTitle.style.marginLeft = `${element?.scrollLeft}px`;
                if (keyFeatures) keyFeatures.style.marginLeft = `${element?.scrollLeft}px`;
            }
        });
    }, [selectedPacksAndEnginesForComparison]);

    useEffect(() => {
        const element = document?.getElementsByClassName(SCROLL_ELEMENT_CLASS_NAME)?.[0];

        const trimsSection = document?.getElementById(`SECTION_${KEY_TRIMS}`);
        const propertiesSection = document?.getElementById(`SECTION_${KEY_PROPERTIES}`);
        const featureSection = document?.getElementById(`SECTION_${KEY_FEATURES}`);

        const width = `${
            (element as HTMLElement)?.clientWidth >
            (selectedPacksAndEnginesForComparison?.length + 1 || 1) * TRIM_DESKTOP_WIDTH_WITH_PADDING + ELEMENT_PADDING
                ? `${(element as HTMLElement)?.clientWidth}px`
                : `${(selectedPacksAndEnginesForComparison?.length + 1 || 1) * TRIM_DESKTOP_WIDTH_WITH_PADDING}px`
        }`;

        if (trimsSection) trimsSection.style.width = width;
        if (propertiesSection) propertiesSection.style.width = width;
        if (featureSection) featureSection.style.width = width;
    }, [selectedPacksAndEnginesForComparison]);

    useStickyListenerDesktop(selectedPacksAndEnginesForComparison);

    const excludedCategoriesStr =
        useContext(FeatureSwitchContext)?.FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES ?? '';
    const isPromoEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_PROMO_ENABLED);
    const { hasAnyTrimOffers, hasAnyTrimLabel, hasAnyTrimDescription, hasAnyTrimMainFeatures } =
        hasTrimLabelOrDescriptionOrTitle(selectedPacksAndEnginesForComparison, allTrims);

    const uniqueFeaturesKeys = [] as string[];

    const renderTrimImageWithOfferLabel = (withId: boolean, withSelect: boolean) => (
        <div className="trims" id={withId ? `SECTION_${KEY_TRIMS}` : null}>
            <div className="sections">
                <div className="trim" />
                {selectedPacksAndEnginesForComparison?.map(
                    (selectedSpecPackAndEngine: ISelectedSpeckPackAndEngine, index: number) => {
                        const selectedTrim = findTrimBySelectedSpeckPackAndEngine(allTrims, selectedSpecPackAndEngine);
                        const hasTrimOffers = findPromoByPageAndType(
                            selectedTrim?.cheapestCar?.offers,
                            EContextPage.trim
                        );

                        return (
                            <div key={`trim-${index}`} className="trim">
                                {isPromoEnabled && hasTrimOffers && (
                                    <div className="trimPromo">
                                        <MarketingSticker
                                            data={
                                                findPromoByPageAndType(
                                                    selectedTrim?.cheapestCar?.offers,
                                                    EContextPage.trim
                                                ) as unknown as Array<IPromo>
                                            }
                                            page={EContextPage.trim}
                                        />
                                    </div>
                                )}
                                {isPromoEnabled && !hasTrimOffers && hasAnyTrimOffers && (
                                    <div className="trimPromoSpace" />
                                )}
                                {withSelect && (
                                    <Select
                                        withBorder
                                        value={{
                                            value: selectedSpecPackAndEngine.specPackId,
                                            label:
                                                selectedSpecPackAndEngine.specPackTitle ||
                                                t('trim.comparator.pack.noSelect.label'),
                                        }}
                                        onChange={(e: { value: string; label: string }) => {
                                            setSelectedPacksAndEnginesForComparison(
                                                selectedPacksAndEnginesForComparison.map(
                                                    (data: ISelectedSpeckPackAndEngine, key: number) => {
                                                        if (key === index) {
                                                            if (!e.value) {
                                                                return {};
                                                            }

                                                            const preSelectedEngine =
                                                                uniquePacksAndEnginesForComparison?.find(
                                                                    (packAndEngines: ISelectedSpeckPackAndEngine) =>
                                                                        packAndEngines.specPackId === e.value
                                                                )?.engines;

                                                            return {
                                                                ...data,
                                                                specPackId: e.value,
                                                                specPackTitle: e.label,
                                                                engineId: preSelectedEngine?.[0].id,
                                                                engineTitle: preSelectedEngine?.[0].title,
                                                            };
                                                        }

                                                        return data;
                                                    }
                                                )
                                            );
                                        }}
                                        options={[
                                            { value: null, label: t('trim.comparator.pack.noSelect.label') },
                                            ...uniquePacksAndEnginesForComparison?.map(
                                                (packAndEngines: ISelectedSpeckPackAndEngine) => ({
                                                    value: packAndEngines.specPackId,
                                                    label: packAndEngines.specPackTitle,
                                                })
                                            ),
                                        ]}
                                    />
                                )}
                                {withSelect && selectedSpecPackAndEngine?.specPackId && (
                                    <Select
                                        withBorder
                                        value={{
                                            value: selectedSpecPackAndEngine.engineId,
                                            label:
                                                selectedSpecPackAndEngine.engineTitle ||
                                                t('trim.comparator.engine.noSelect.label'),
                                        }}
                                        onChange={(e: { value: string; label: string }) => {
                                            setSelectedPacksAndEnginesForComparison(
                                                selectedPacksAndEnginesForComparison.map(
                                                    (data: ISelectedSpeckPackAndEngine, key: number) => {
                                                        if (key === index) {
                                                            return { ...data, engineId: e.value, engineTitle: e.label };
                                                        }

                                                        return data;
                                                    }
                                                )
                                            );
                                        }}
                                        options={uniquePacksAndEnginesForComparison
                                            ?.find(
                                                (packAndEngines: ISelectedSpeckPackAndEngine) =>
                                                    packAndEngines.specPackId === selectedSpecPackAndEngine.specPackId
                                            )
                                            ?.engines?.map((engine: { id: string; title: string }) => ({
                                                value: engine.id,
                                                label: engine.title,
                                            }))}
                                    />
                                )}
                                <img className="trimImage" src={getMainImageUrl(selectedTrim?.cheapestCar)} />
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );

    const renderTrimName = (withId: boolean, withEngine: boolean) => (
        <div className="trims stickyTrimName" id={withId ? KEY_STICKY_NAME : null}>
            {isLoading ? (
                <Spinner border={2} size={25} color="black" />
            ) : (
                <div className="sections">
                    <div className="trim" />

                    {selectedPacksAndEnginesForComparison?.map(
                        (selectedSpecPackAndEngine: { specPackId: string; engineId: string }, index: number) => {
                            const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                allTrims,
                                selectedSpecPackAndEngine
                            );

                            return selectedTrim ? (
                                <div key={`trim-${index}`} className="trim">
                                    <span className="trimName">{selectedTrim?.cheapestCar?.specPack?.title}</span>
                                    {withEngine && (
                                        <span className="trimEngine" id="trimEngine">
                                            {`${selectedTrim?.cheapestCar?.fuel}, ${
                                                selectedTrim?.cheapestCar?.gearbox?.specs.find(
                                                    ({ key }: any) => key === GEARBOX_SEARCH_KEY
                                                )?.label
                                            } ${
                                                selectedTrim?.cheapestCar?.characteristics?.find(
                                                    ({ key }: any) => key === MAXIMUM_POWER_KW
                                                )?.value
                                                    ? `, ${
                                                          selectedTrim?.cheapestCar?.characteristics?.find(
                                                              ({ key }: any) => key === MAXIMUM_POWER_KW
                                                          )?.value
                                                      } ${t('configurator.info.power.label')}`
                                                    : ''
                                            }`}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <div className="trim" />
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );

    const renderTrimDescription = () => (
        <div className="trims">
            {isLoading ? (
                <Spinner border={2} size={25} color="black" />
            ) : (
                <div className="sections">
                    <div className="trim" />

                    {selectedPacksAndEnginesForComparison?.map(
                        (selectedSpecPackAndEngine: { specPackId: string; engineId: string }, index: number) => {
                            const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                allTrims,
                                selectedSpecPackAndEngine
                            );

                            return selectedTrim ? (
                                <div key={`trim-${index}`} className="trim">
                                    <span className="trimLabel">
                                        {selectedTrim?.cheapestCar?.trimContent?.title
                                            ? parser.toReact(selectedTrim.cheapestCar.trimContent.title)
                                            : null}
                                    </span>
                                    <span className="trimDescription">
                                        {selectedTrim?.cheapestCar?.trimContent?.description
                                            ? parser.toReact(selectedTrim.cheapestCar.trimContent.description)
                                            : null}
                                    </span>
                                </div>
                            ) : (
                                <div className="trim" />
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );

    const renderTrimPrice = (withId: boolean) => (
        <div className="trims stickyTrimPrice" id={withId ? KEY_STICKY_PRICE : null}>
            {isLoading ? (
                <Spinner border={2} size={25} color="black" />
            ) : (
                <div className="sections">
                    <div className="trim" />

                    {selectedPacksAndEnginesForComparison?.map(
                        (selectedSpecPackAndEngine: { specPackId: string; engineId: string }, index: number) => {
                            const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                allTrims,
                                selectedSpecPackAndEngine
                            );
                            const priceObj = getTotalPriceObjByType(
                                selectedTrim?.cheapestCar?.extraFields?.pricesV2 || selectedTrim?.cheapestCar?.pricesV2,
                                IPriceV2Types.TOTAL_B2C_CASH
                            );
                            const monthlyPrice = getMonthlyPriceObjByType(
                                selectedTrim?.cheapestCar?.prices,
                                EMPLOYEE_PRICE_TYPE
                            );

                            return selectedTrim ? (
                                <div key={`trim-${index}`} className="trim">
                                    <span className="trimPrice">
                                        {t('nameplate.priceFrom')}{' '}
                                        {formatMoney(
                                            isSOLEnabled
                                                ? priceObj?.breakdown.finalCustomerCashPriceOnTheRoad
                                                : priceObj?.finalPriceInclTax,
                                            true
                                        )}{' '}
                                        {monthlyPrice && (
                                            <span className="monthlyPrice">
                                                <span className="or">{t('configurator.optionPrice.suffix')}</span>{' '}
                                                {formatMoney(monthlyPrice.amount, true)}
                                                <PriceSuffix isMonthlyPrice hideAsterisk />
                                            </span>
                                        )}
                                        {monthlyPrice && isMarketGB && (
                                            <MonthlyPriceLabel
                                                withPrimaryColor={isMarketGB}
                                                aprValue={monthlyPrice.apr}
                                                size={AprSizes.SMALL}
                                                calculateSummaryData={() =>
                                                    calculateSummaryData(
                                                        selectedTrim.cheapestCar,
                                                        dispatch,
                                                        filteredTrims?.length
                                                    )
                                                }
                                            />
                                        )}
                                    </span>
                                </div>
                            ) : (
                                <div className="trim" />
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );

    const renderTrimButton = (withId: boolean) => (
        <div className="trims stickyTrimButton" id={withId ? KEY_STICKY_BUTTON : null}>
            {isLoading ? (
                <Spinner border={2} size={25} color="black" />
            ) : (
                <div className="sections">
                    <div className="trim" />

                    {selectedPacksAndEnginesForComparison?.map(
                        (selectedSpecPackAndEngine: { specPackId: string; engineId: string }, index: number) => {
                            const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                allTrims,
                                selectedSpecPackAndEngine
                            );

                            return selectedTrim ? (
                                <div key={`trim-${index}`} className="trim">
                                    <Button
                                        primary
                                        className="selectButton"
                                        marginBottom={20}
                                        onClick={() =>
                                            redirectToConfigurator(
                                                selectedTrim?.cheapestCar,
                                                dispatch,
                                                router,
                                                carJourney
                                            )
                                        }
                                    >
                                        {t('trim.comparator.select')}
                                    </Button>
                                </div>
                            ) : (
                                <div className="trim" />
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );

    return (
        <div className={`${className} test`} id="trims" data-testid={`${TESTING_PREFIX}modal`}>
            <div className="trims stickyTrimAvailability" id={KEY_STICKY_AVAILABILITY}>
                <BackButton
                    id={KEY_STICKY_BACK_BUTTON}
                    onClick={() => dispatch(UIDuck.closeModal(UIDuck.MODAL_TYPES.TRIM_COMPARE))}
                >
                    <IconTemplate name={Icons.ArrowUp} />
                    <span className="label">{t('trim.comparator.backBtn')}</span>
                </BackButton>
                <div className="title" id={`TITLE_${KEY_TRIMS}`}>
                    <span className="txt" id={`TITLE_TXT_${KEY_TRIMS}`}>
                        {t('trim.comparator.title')}
                    </span>
                </div>
            </div>
            <div className="availability" id={KEY_STICKY_SELECT}>
                <span className="item">
                    <div className="icon available" />
                    {t('trim.comparator.availability.available')}
                </span>
                <span className="item">
                    <div className="icon optional" />
                    {t('trim.comparator.availability.optional')}
                </span>
                <span className="item">
                    <div className="icon unavailable" />
                    {t('trim.comparator.availability.noAvailable')}
                </span>
            </div>
            {renderTrimImageWithOfferLabel(true, true)}
            {renderTrimName(true, true)}
            {(hasAnyTrimLabel || hasAnyTrimDescription) && renderTrimDescription()}
            {renderTrimPrice(true)}
            {renderTrimButton(true)}
            <div className="properties" id={`SECTION_${KEY_PROPERTIES}`} data-testid={`${TESTING_PREFIX}properties`}>
                {hasAnyTrimMainFeatures && (
                    <div
                        className="keyFeatures"
                        id={`SECTION_${KEY_STRONG_POINTS}`}
                        data-testid={`${TESTING_PREFIX}section${KEY_STRONG_POINTS}`}
                    >
                        <span className="label" id={`TITLE_${KEY_STRONG_POINTS}`}>
                            {t('modal.features.point.title')}
                        </span>
                        {isLoading ? (
                            <Spinner border={2} size={25} color="black" />
                        ) : (
                            <div
                                className="sections"
                                data-testid={`${TESTING_PREFIX}items-section${KEY_STRONG_POINTS}`}
                            >
                                <div className="keyFeaturesConfig">
                                    {uniqueFeatures.map((feature: string, featureKey: number) => {
                                        return (
                                            <div key={`keyFeatures-${featureKey}`} className="config">
                                                <span className="configDetails">{feature}</span>
                                            </div>
                                        );
                                    })}
                                </div>

                                {selectedPacksAndEnginesForComparison?.map(
                                    (
                                        selectedSpecPackAndEngine: { specPackId: string; engineId: string },
                                        index: number
                                    ) => {
                                        const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                            allTrims,
                                            selectedSpecPackAndEngine
                                        );

                                        return selectedTrim ? (
                                            <div key={`keyFeatures-${index}`} className="keyFeaturesConfig">
                                                {uniqueFeatures.map((feature: string, featureKey: number) => {
                                                    return (
                                                        <div key={`keyFeatures-${featureKey}`} className="config">
                                                            <span className="configDetails hidden">
                                                                <span className="configDetails">{feature}</span>
                                                            </span>
                                                            <div
                                                                className={`iconAvailability ${
                                                                    isFeatureAvailableInConfiguration(
                                                                        selectedTrim?.cheapestCar?.seriesEquipment,
                                                                        feature
                                                                    )
                                                                        ? 'default'
                                                                        : 'noAvailable'
                                                                }`}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <div className="keyFeaturesConfig" />
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div
                    className="keyFeatures"
                    id={`SECTION_${KEY_ENGINES}`}
                    data-testid={`${TESTING_PREFIX}section${KEY_ENGINES}`}
                >
                    <span className="label" id={`TITLE_${KEY_ENGINES}`}>
                        {t('modal.features.engine.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="sections" data-testid={`${TESTING_PREFIX}items-section${KEY_ENGINES}`}>
                            <div className="keyFeaturesConfig">
                                <div className="config">
                                    <span className="configDetails">
                                        {t('technicalFeatures.environmentalDetails.name')}
                                    </span>
                                </div>
                                {(isSomeSelectedVehicleAsElectric || isSomeSelectedVehicleAsPlugInHybrid) && (
                                    <div className="config">
                                        <span className="configDetails">
                                            {t('technicalFeatures.environmentalDetails.consumption.electricRange')}
                                        </span>
                                    </div>
                                )}
                                {isSomeSelectedVehicleAsDieselOrBensinOrHybrid && (
                                    <div className="config">
                                        <span className="configDetails">
                                            {t('technicalFeatures.environmentalDetails.engine.power')}
                                        </span>
                                    </div>
                                )}
                                <div className="config">
                                    <span className="configDetails">
                                        {t('technicalFeatures.environmentalDetails.gearbox')}
                                    </span>
                                </div>
                                <div className="config">
                                    <span className="configDetails">
                                        {t('technicalFeatures.environmentalDetails.consumption.co2')}
                                    </span>
                                </div>
                                {(isSomeSelectedVehicleAsDieselOrBensinOrHybrid ||
                                    isSomeSelectedVehicleAsPlugInHybrid) && (
                                    <div className="config">
                                        <span className="configDetails">
                                            {t('technicalFeatures.environmentalDetails.consumption.mix')}
                                        </span>
                                    </div>
                                )}
                                {isSomeSelectedVehicleAsElectric && (
                                    <div className="config">
                                        <span className="configDetails">
                                            {t(
                                                'technicalFeatures.environmentalDetails.consumption.electricConsumption'
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {selectedPacksAndEnginesForComparison?.map(
                                (
                                    selectedSpecPackAndEngine: { specPackId: string; engineId: string },
                                    index: number
                                ) => {
                                    const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                        allTrims,
                                        selectedSpecPackAndEngine
                                    );
                                    const { isBenzin, isDiesel, isHybrid, isPlugInHybrid } = getFuelTypeByCode(
                                        selectedTrim?.cheapestCar?.fuelCode
                                    );
                                    const isBenzinOrDiesel = isDiesel || isBenzin;

                                    const localConsumption = getData(
                                        selectedTrim?.cheapestCar?.engine.specs,
                                        'consumptionCombined',
                                        'local_value'
                                    );
                                    const consumption =
                                        localConsumption ??
                                        getData(
                                            selectedTrim?.cheapestCar?.engine.specs,
                                            'consumptionCombined',
                                            'value'
                                        );
                                    const localElectricRange = getData(
                                        selectedTrim?.cheapestCar?.engine.specs,
                                        'electricRange',
                                        'local_value'
                                    );
                                    const electricRange =
                                        localElectricRange ??
                                        getData(selectedTrim?.cheapestCar?.engine.specs, 'electricRange', 'value');

                                    return selectedTrim ? (
                                        <div key={`keyFeatures-${index}`} className="keyFeaturesConfig">
                                            <div className="config">
                                                <span className="configDetails">
                                                    <span className="configDetails">
                                                        {selectedTrim?.cheapestCar?.fuel}
                                                    </span>
                                                </span>
                                            </div>
                                            {(isSomeSelectedVehicleAsElectric ||
                                                isSomeSelectedVehicleAsPlugInHybrid) && (
                                                <div className="config">
                                                    <span className="configDetails">
                                                        <span className="configDetails">{electricRange || '-'}</span>
                                                    </span>
                                                </div>
                                            )}
                                            {isSomeSelectedVehicleAsDieselOrBensinOrHybrid && (
                                                <div className="config">
                                                    <span className="configDetails">
                                                        <span className="configDetails">
                                                            {getData(
                                                                selectedTrim?.cheapestCar?.engine?.specs,
                                                                'power',
                                                                'value'
                                                            )}{' '}
                                                            {t(
                                                                'technicalFeatures.environmentalDetails.engine.power.label'
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            )}
                                            <div className="config">
                                                <span className="configDetails">
                                                    <span className="configDetails">
                                                        {getData(
                                                            selectedTrim?.cheapestCar?.gearbox?.specs,
                                                            'type',
                                                            'label'
                                                        )}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="config">
                                                <span className="configDetails">
                                                    <span className="configDetails">
                                                        {getData(
                                                            selectedTrim?.cheapestCar?.engine.specs,
                                                            'co2Combined',
                                                            'value'
                                                        )}
                                                        {t(
                                                            `technicalFeatures.environmentalDetails.consumption.co2.label`
                                                        )}
                                                    </span>
                                                </span>
                                            </div>
                                            {(isSomeSelectedVehicleAsDieselOrBensinOrHybrid ||
                                                isSomeSelectedVehicleAsPlugInHybrid) && (
                                                <div className="config">
                                                    <span className="configDetails">
                                                        {isBenzinOrDiesel || isHybrid || isPlugInHybrid ? (
                                                            <span className="configDetails">
                                                                {consumption}
                                                                {t(
                                                                    `technicalFeatures.environmentalDetails.consumption.mix.label`
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span className="configDetails">-</span>
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {isSomeSelectedVehicleAsElectric && (
                                                <div className="config">
                                                    <span className="configDetails">
                                                        <span className="configDetails">
                                                            {!isBenzinOrDiesel && !isHybrid
                                                                ? getData(
                                                                      selectedTrim?.cheapestCar?.engine.specs,
                                                                      'electricConsumption',
                                                                      'value'
                                                                  )
                                                                : '-'}
                                                        </span>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="keyFeaturesConfig" />
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
                <div className="colors" id={`SECTION_${KEY_COLORS}`}>
                    <span className="label" id={`TITLE_${KEY_COLORS}`}>
                        {t('modal.features.color.title')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="sections">
                            <div className="configurations">
                                {uniqueExteriorColors.map((exteriorColor: IFeature, exteriorColorKey: number) => {
                                    return (
                                        <div key={`exteriorColor-${exteriorColorKey}`} className="exterior">
                                            <span className="name">
                                                <img src={`${exteriorColor.urls[0]}`} />
                                                {exteriorColor.label}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>

                            {selectedPacksAndEnginesForComparison?.map(
                                (
                                    selectedSpecPackAndEngine: { specPackId: string; engineId: string },
                                    index: number
                                ) => {
                                    const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                        allTrims,
                                        selectedSpecPackAndEngine
                                    );

                                    return selectedTrim ? (
                                        <div key={`exteriorColors-${index}`} className="configurations">
                                            {uniqueExteriorColors.map(
                                                (exteriorColor: IFeature, exteriorColorKey: number) => {
                                                    return (
                                                        <div
                                                            key={`exteriorColor-${exteriorColorKey}`}
                                                            className="exterior"
                                                        >
                                                            <span className="name hidden">
                                                                <img src={`${exteriorColor.urls[0]}`} />
                                                                {exteriorColor.label}
                                                            </span>
                                                            <div
                                                                className={`iconAvailability ${
                                                                    isExteriorColorAvailableInConfiguration(
                                                                        selectedTrim,
                                                                        exteriorColor
                                                                    )
                                                                        ? selectedTrim?.cheapestCar?.exteriorColour
                                                                              ?.id === exteriorColor?.id
                                                                            ? 'default'
                                                                            : 'optional'
                                                                        : 'noAvailable'
                                                                }`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : (
                                        <div className="keyFeaturesConfig" />
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
                <div className="interiorsAvailable" id={`SECTION_${KEY_INTERIORS}`}>
                    <span className="label" id={`TITLE_${KEY_INTERIORS}`}>
                        {t('modal.features.keys.interior')}
                    </span>
                    {isLoading ? (
                        <Spinner border={2} size={25} color="black" />
                    ) : (
                        <div className="sections">
                            <div className="interiors">
                                {uniqueInteriorColors.map((interiorColor: IFeature, interiorColorKey: number) => {
                                    return (
                                        <div key={`interiorColor-${interiorColorKey}`} className="interior">
                                            <span className="name">{interiorColor.label}</span>
                                        </div>
                                    );
                                })}
                            </div>

                            {selectedPacksAndEnginesForComparison?.map(
                                (
                                    selectedSpecPackAndEngine: { specPackId: string; engineId: string },
                                    index: number
                                ) => {
                                    const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                        allTrims,
                                        selectedSpecPackAndEngine
                                    );

                                    return selectedTrim ? (
                                        <div key={`interiorColors-${index}`} className="interiors">
                                            {uniqueInteriorColors.map(
                                                (interiorColor: IFeature, interiorColorKey: number) => {
                                                    return (
                                                        <div
                                                            key={`${index}-interiorColor-${interiorColorKey}`}
                                                            className="interior"
                                                        >
                                                            <span className="name hidden">{interiorColor.label}</span>
                                                            <div
                                                                className={`iconAvailability ${
                                                                    isInteriorAvailableInConfiguration(
                                                                        selectedTrim,
                                                                        interiorColor
                                                                    )
                                                                        ? selectedTrim?.cheapestCar?.interiorColour
                                                                              ?.id === interiorColor?.id
                                                                            ? 'default'
                                                                            : 'optional'
                                                                        : 'noAvailable'
                                                                }`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : (
                                        <div className="interiors" />
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="options withoutBorder">
                <span className="label" id={`TITLE_${KEY_FEATURES}`}>
                    {t('modal.features.feature.title')}
                </span>
                {isLoading ? (
                    <Spinner border={2} size={25} color="black" />
                ) : (
                    <div className="filter">
                        <button
                            className={activeFeature === KEY_ALL ? SELECTED : ''}
                            onClick={() => setActiveFeature(KEY_ALL)}
                        >
                            {t('modal.features.keys.all')}
                        </button>
                        {selectedPacksAndEnginesForComparison?.map(
                            (selectedSpecPackAndEngine: { specPackId: string; engineId: string }, index: number) => {
                                const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                    allTrims,
                                    selectedSpecPackAndEngine
                                );

                                orderBy(selectedTrim?.cheapestCar?.standardFeatures, ['category'], ['asc'])
                                    ?.filter(
                                        (feature: { key: string }) =>
                                            !excludedCategoriesStr?.includes(
                                                `${STANDARD_FEATURES_FS_PREFIX}:${feature.key}`
                                            )
                                    )
                                    .map((feature: { key: string; category: string }, featureKey: number) => {
                                        if (!uniqueFeaturesKeys.includes(feature.category)) {
                                            uniqueFeaturesKeys.push(feature.category);

                                            return (
                                                <button
                                                    key={`features-${index}-${featureKey}`}
                                                    className={activeFeature === feature.category ? SELECTED : ''}
                                                    onClick={() => setActiveFeature(feature.category)}
                                                >
                                                    {feature.category}
                                                </button>
                                            );
                                        }
                                    });
                            }
                        )}
                    </div>
                )}
            </div>
            <div id={`SECTION_${KEY_FEATURES}`}>
                {uniqueFeaturesKeys.map((uniqueFeatureCategory: string, renderedFeatureKey: number) => {
                    if (activeFeature === KEY_ALL || activeFeature === uniqueFeatureCategory) {
                        return (
                            <div key={`feature-${renderedFeatureKey}`} className="featuresAvailable">
                                <Collapsible
                                    open
                                    trigger={
                                        <span className="label">
                                            {uniqueFeatureCategory} <Icon name={Icons.ChevronDown} />
                                        </span>
                                    }
                                >
                                    <div className="sections">
                                        <div className="features">
                                            {getUniqueEquipments(
                                                selectedTrims,
                                                excludedCategoriesStr,
                                                uniqueFeatureCategory
                                            ).map((equipment, equipmentKey) => {
                                                return (
                                                    <div
                                                        className="feature"
                                                        id={`feature-${equipment.key}`}
                                                        key={equipmentKey}
                                                    >
                                                        <span
                                                            className="name"
                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                        >
                                                            {equipment.label}
                                                            {equipment.description && (
                                                                <IconButton
                                                                    icon={
                                                                        isBrandAC ||
                                                                        isBrandAP ||
                                                                        isBrandDS ||
                                                                        (isBrandOV && !isMarketGB)
                                                                            ? Icons.InfoCircle
                                                                            : Icons.Info
                                                                    }
                                                                    width={20}
                                                                    height={20}
                                                                    role={ROLES.IMG}
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            UIDuck.openModal({
                                                                                data: {
                                                                                    category: equipment?.category,
                                                                                    image: equipment?.images?.[0]?.[
                                                                                        'url'
                                                                                    ],
                                                                                    description: renderDescription(
                                                                                        equipment.description
                                                                                    ),
                                                                                    title: equipment.label,
                                                                                },
                                                                                modalType:
                                                                                    UIDuck.MODAL_TYPES
                                                                                        .CAR_OPTION_DETAIL,
                                                                                modalVersion: ModalVersion.v2,
                                                                                callbacks: {
                                                                                    openPreviousModalType:
                                                                                        UIDuck.MODAL_TYPES.TRIM_COMPARE,
                                                                                    modalProperties: {
                                                                                        withoutPadding: true,
                                                                                        fullWidthOnDesktop:
                                                                                            filteredTrims?.length >= 4,
                                                                                        middleWidthOnDesktop:
                                                                                            filteredTrims?.length === 3,
                                                                                    },
                                                                                },
                                                                            })
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {selectedPacksAndEnginesForComparison?.map(
                                            (
                                                selectedSpecPackAndEngine: { specPackId: string; engineId: string },
                                                index: number
                                            ) => {
                                                const selectedTrim = findTrimBySelectedSpeckPackAndEngine(
                                                    allTrims,
                                                    selectedSpecPackAndEngine
                                                );

                                                return selectedTrim && dipslaySelectedTrimValues ? (
                                                    <div key={`${index}-feature`} className="features">
                                                        {getUniqueEquipments(
                                                            selectedTrims,
                                                            excludedCategoriesStr,
                                                            uniqueFeatureCategory
                                                        ).map((equipment) => {
                                                            const isAvailable = isEquipmentAvailableInConfiguration(
                                                                selectedTrim?.cheapestCar?.standardFeatures,
                                                                excludedCategoriesStr,
                                                                uniqueFeatureCategory,
                                                                equipment.key
                                                            );

                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="feature"
                                                                    style={{
                                                                        height: `${
                                                                            document.getElementById(
                                                                                `feature-${equipment.key}`
                                                                            )?.offsetHeight
                                                                        }px`,
                                                                    }}
                                                                >
                                                                    <span className="name hidden">
                                                                        {equipment.label}
                                                                    </span>
                                                                    <div
                                                                        className={`iconAvailability ${
                                                                            isAvailable ? 'default' : 'noAvailable'
                                                                        }`}
                                                                        style={{
                                                                            top: `calc(50% - ${
                                                                                isAvailable ? 10 : 2
                                                                            }px)`,
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ) : (
                                                    <div className="features" />
                                                );
                                            }
                                        )}
                                    </div>
                                </Collapsible>
                            </div>
                        );
                    }
                })}
            </div>
            {renderTrimImageWithOfferLabel(false, false)}
            {renderTrimName(false, false)}
            {(hasAnyTrimLabel || hasAnyTrimDescription) && renderTrimDescription()}
            {renderTrimPrice(false)}
            {renderTrimButton(false)}
        </div>
    );
};

export default ModalTrimCompareTemplateDesktop;
